import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { RegularButtonComponent } from '../regular-button/regular-button.component';
import { ButtonType } from '../../models/button-type.type';
import { IColorScheme } from '../../models/color-scheme.interface';

@Component({
  selector: 'kds-large-button',
  templateUrl: './large-button.component.html',
  styleUrls: ['./large-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [RegularButtonComponent],
})
export class LargeButtonComponent {
  @Input() colorScheme: IColorScheme = 'primary';
  @Input() disabled: boolean;
  @Input() buttonText: string;
  @Input() loading: boolean;
  @Input() testId: string;
  @Input() isCritical: boolean;
  @Input() type?: ButtonType;
  @Input() alignContentToRight = false;

  @Output() buttonClick = new EventEmitter();
}
